<template>
  <div class="content">
    <div class="top__container">
      <div class="cards__container">
        <Card
          title="Niveles"
          icon="suitcase"
          :value="Object.keys(data).length"
          :colors="['var(--font-color-600)', 'var(--gray-color-100)']"
        />
        <Card
          title="Empleados"
          icon="user"
          :value="filteredEmployees.length"
          :colors="['var(--font-color-600)', 'var(--gray-color-100)']"
        />
        <Card
          title="Hombres"
          icon="mars"
          :value="filteredEmployees.filter((employee) => employee.gender === 'Masculino').length"
          :total="filteredEmployees.length"
          :colors="['var(--main-color-500)', 'var(--main-color-100)']"
        />
        <Card
          title="Mujeres"
          icon="venus"
          :value="filteredEmployees.filter((employee) => employee.gender === 'Femenino').length"
          :total="filteredEmployees.length"
          :colors="['var(--danger-color-400)', 'var(--danger-color-100)']"
        />
        <Card
          title="Media"
          subTitle="Brecha salarial"
          icon="money-bill"
          :value="`${getMean(tableData.map(({ meanWageGap }) => meanWageGap)).toFixed(0)}%`"
          :colors="['var(--success-color-500)', 'var(--success-color-100)']"
        />
        <Card
          title="Mediana"
          subTitle="Brecha salarial"
          icon="money-bill"
          :value="`${getMean(tableData.map(({ medianWageGap }) => medianWageGap)).toFixed(0)}%`"
          :colors="['var(--success-color-500)', 'var(--success-color-100)']"
        />
      </div>
    </div>

    <div class="bottom__container">
      <div>
        <h4>Nivel laboral</h4>

        <div>
          <date-filter-item
            type="date"
            :value="selectedDate"
            name="Hoy día"
            @remove="
              (e) => {
                selectedDate = null;
                $emit('filter', selectedDate);
              }
            "
            @change="
              (e) => {
                selectedDate = e;
                $emit('filter', selectedDate);
              }
            "
          />
        </div>
      </div>
      <div class="table__container">
        <table ref="table">
          <tbody>
            <tr>
              <th rowspan="2" class="number">#</th>
              <th rowspan="2">Categoria</th>
              <th rowspan="2">Hombres</th>
              <th rowspan="2">Mujeres</th>
              <th colspan="3" class="center">Media</th>
              <th colspan="3" class="center">Mediana</th>
              <th rowspan="2"></th>
            </tr>
            <tr class="second__header">
              <th>Hombres</th>
              <th>Mujeres</th>
              <th>Brecha salarial</th>
              <th>Hombres</th>
              <th>Mujeres</th>
              <th>Brecha salarial</th>
            </tr>
            <tr v-for="(row, index) in tableData" :key="row.categoryOption.id">
              <td class="number">{{ index + 1 }}</td>
              <td>{{ row.categoryOption.name }}</td>
              <template>
                <td>
                  {{ row.males.length }}
                </td>
                <td>
                  {{ row.females.length }}
                </td>
                <td>
                  {{
                    getMean(row.males).toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  {{
                    getMean(row.females).toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  <span
                    class="gap__cell"
                    :class="
                      row.meanWageGap < 0
                        ? 'gap__cell--female'
                        : row.meanWageGap > 0
                        ? 'gap__cell--male'
                        : 'gap__cell'
                    "
                  >
                    {{
                      row.meanWageGap.toLocaleString('en', {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}%
                  </span>
                </td>
                <td>
                  {{
                    (getMedian(row.males) || 0).toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  {{
                    (getMedian(row.females) || 0).toLocaleString('en', {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
                <td>
                  <span
                    class="gap__cell"
                    :class="
                      row.medianWageGap < 0
                        ? 'gap__cell--female'
                        : row.medianWageGap > 0
                        ? 'gap__cell--male'
                        : 'gap__cell'
                    "
                  >
                    {{
                      row.medianWageGap.toLocaleString('en', {
                        useGrouping: true,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    }}%
                  </span>
                </td>
                <td class="distribution">
                  <a @click="redirectToPosition(row.categoryOption.id)">
                    Puestos <unicon name="angle-right" height="20" width="20"></unicon>
                  </a>
                </td>
              </template>
            </tr>
          </tbody>
        </table>

        <div class="employees__empty-state" v-if="!Object.keys(data || {}).length">
          <p>No hay resultados que mostrar con la búsqueda ingresada.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from 'vuex';
import Card from '@/components/Card.vue';
import DateFilterItem from '@/components/filters/DateFilterItem.vue';
import { dateToYMD } from '@/dateFormats';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';

export default {
  components: {
    Card,
    DateFilterItem,
  },

  data() {
    return {
      selectedDate: null,
      dateToYMD,
    };
  },

  props: {
    filteredEmployees: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    data: {
      type: Object,
    },
    incomeFilter: {
      default: Object,
    },
    filters: {
      type: Array,
    },
  },

  mounted() {
    if (this.stateDate) {
      this.selectedDate = this.dateToYMD(this.stateDate);
      this.$emit('filter', this.selectedDate);
    }
  },

  computed: {
    ...mapState({
      stateDate: (state) => state.selectedDate,
    }),
  },

  methods: {
    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    downloadExcel() {
      const sheet = XLSX.utils.table_to_sheet(this.$refs.table);
      const lastRow = this.tableData.length + 3;
      sheet[`C${lastRow}`] = {
        t: 'n',
        v: this.filteredEmployees.filter((employee) => employee.gender === 'Masculino').length,
      };
      sheet[`D${lastRow}`] = {
        t: 'n',
        v: this.filteredEmployees.filter((employee) => employee.gender === 'Femenino').length,
      };
      sheet[`G${lastRow}`] = {
        t: 'n',
        v: this.getMean(this.tableData.map(({ meanWageGap }) => meanWageGap)) / 100,
      };
      sheet[`J${lastRow}`] = {
        t: 'n',
        v: this.getMean(this.tableData.map(({ medianWageGap }) => medianWageGap)) / 100,
      };
      sheet['!ref'] = `A1:J${this.tableData.length + 3}`;

      const workbook = {
        SheetNames: ['Nivel laboral'],
        Sheets: { 'Nivel laboral': sheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });
      this.saveBlobAsFile(
        'reporte.xlsx',
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },

    getMean(arr) {
      return parseFloat(arr.reduce((p, c, i) => p + (c - p) / (i + 1), 0).toFixed(1), 10);
    },

    getMedian(arr) {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },

    redirectToPosition(categoryId) {
      this.$router.push({
        path: `${this.$router.currentRoute.fullPath}/puestos`,
        name: 'Positions',
        params: {
          data: this.data[categoryId],
          filteredEmployees: Object.entries(this.data[categoryId])
            .map((entry) => Object.entries(entry[1]).map((entry2) => entry2[1]))
            .flat(1)
            .flat(1),
          categoryId,
          filters: this.filters,
          selectedDate: this.selectedDate,
          incomeFilter: this.incomeFilter,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  // background-color: #F6F9FF;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

.content {
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  gap: 1rem;
  height: 100%;
  overflow: auto;

  & > *:first-child {
    display: flex;
    justify-content: space-between;
  }
}

.top__container {
  height: 96px;
  display: flex;
  gap: 1rem;

  & > div:nth-child(2) {
    flex-grow: 1;
    padding-right: 0.25rem;
    display: flex;
    flex-flow: column;
  }

  .cards__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    width: 100%;
  }
}

.bottom__container {
  border: solid 1px var(--gray-color-500);
  background-color: white;
  border-radius: 3px;
  flex-grow: 1;
  overflow: hidden;
  padding: 1rem;
  padding-bottom: 0;

  & > *:first-child {
    display: flex;
    justify-content: space-between;
    // border-bottom: 1px solid var(--gray-color-500);
    padding-bottom: 1rem;

    & > div {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.table__container {
  border-radius: 5px;
  height: calc(100% - 4.25rem);
  overflow: auto;
  border-radius: 8px;

  table {
    min-width: 100%;

    tr td:nth-child(2) {
      // color: var(--main-color-500);
      font-weight: var(--semi-bold);
    }

    tr:first-child th:last-child {
      border-top-right-radius: 0px;
    }

    .center {
      text-align: center;
    }
    .gap__cell {
      padding: 0.375rem;
      font-size: 0.75rem;
      font-weight: var(--medium);
      border-radius: 4px;
      text-align: center;
      color: var(--success-color-600);
      background-color: var(--success-color-100);

      &--male {
        color: var(--danger-color-500);
        background-color: var(--danger-color-100);
      }
      &--female {
        color: var(--font-color-500);
        background-color: var(--gray-color-200);
      }
    }

    .distribution a {
      cursor: pointer;
      color: var(--main-color-500);
      fill: var(--main-color-500);
      display: flex;
      align-items: center;
      &:hover {
        text-decoration: underline var(--main-color-500);
      }
    }
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.employees__empty-state {
  height: calc(100% - 5.325rem);
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-style: italic;
  }
}
</style>
