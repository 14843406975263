<template>
  <div class="gender-chart__section">
    <div class="section__title">
      <h4>Género de puestos</h4>
    </div>
    <div class="chart__container">
      <doughnut-chart
        class="chart"
        :chart-data="genderData"
        :chart-options="{
          cutout: 0,
          radius: '95%',
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DoughnutChart from '@/components/charts/DoughnutChart.vue';

export default {
  components: { DoughnutChart },
  data() {
    return {};
  },
  props: {
    filteredEmployees: {
      type: Array,
    },
  },
  methods: {},
  computed: {
    ...mapState({
      company: (state) => ({
        ...state.company,
        genderRatio: state.company.genderRatio || 0.6,
        colors: state.company.colors,
      }),
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),
    genderData() {
      const categoryId = 'puesto';
      const categoryOptions = this.options
        .filter((option) => option.categoryId === categoryId)
        .map((option, index) => ({ ...option, index }));
      let data = [categoryOptions.map(() => 0), categoryOptions.map(() => 0)];
      const dataTotals = categoryOptions.map(() => 0);
      this.filteredEmployees.forEach((employee) => {
        const employeeGenderIndex = employee.gender === 'Masculino' ? 0 : 1;
        const option = categoryOptions.find(({ id }) => id === employee[categoryId]);
        if (option) {
          data[employeeGenderIndex][option.index] += 1;
          dataTotals[option.index] += 1;
        }
      });
      data = data.map((item) => item.map((val, index) => val / dataTotals[index] || 0));
      const malePositions = data[0].filter((item) => item >= this.company.genderRatio).length;
      const femalePositions = data[1].filter((item) => item >= this.company.genderRatio).length;
      const genderPositions = [
        malePositions,
        femalePositions,
        categoryOptions.length - malePositions - femalePositions,
      ];
      return {
        labels: ['Masculinos', 'Femeninos', 'Neutros'],
        datasets: [
          {
            backgroundColor: [...this.company.colors, '#70B5C0'],
            data: genderPositions.map((val) => val / (categoryOptions.length || 0)),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.gender-chart__section {
  gap: 0.5rem;
  .chart__container {
    flex-grow: 1;
    .chart {
      height: 100%;
    }
  }
  .chart {
    height: 100%;
    width: 80%;
    margin: auto;
  }
}
</style>
