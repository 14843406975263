import { render, staticRenderFns } from "./CategoryBarChart.vue?vue&type=template&id=b398b91e&scoped=true&"
import script from "./CategoryBarChart.vue?vue&type=script&lang=js&"
export * from "./CategoryBarChart.vue?vue&type=script&lang=js&"
import style0 from "./CategoryBarChart.vue?vue&type=style&index=0&id=b398b91e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b398b91e",
  null
  
)

export default component.exports