var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category-chart__section"},[_vm._m(0),_c('div',{staticClass:"chart__container"},[_c('bar-chart',{staticClass:"chart",attrs:{"chart-data":_vm.categoryData,"chart-options":{
        stepSize: 0.1,
        indexAxis: 'y',
        scales: {
          y: {
            position: 'right',
          },
        },
        plugins: {
          tooltip: {
            yAlign: 'bottom',
          },
        },
      }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section__title"},[_c('h4',[_vm._v("Brecha salarial nivel laboral")])])}]

export { render, staticRenderFns }