var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"top__container"},[_c('div',{staticClass:"cards__container"},[_c('Card',{attrs:{"title":"Niveles","icon":"suitcase","value":Object.keys(_vm.data).length,"colors":['var(--font-color-600)', 'var(--gray-color-100)']}}),_c('Card',{attrs:{"title":"Empleados","icon":"user","value":_vm.filteredEmployees.length,"colors":['var(--font-color-600)', 'var(--gray-color-100)']}}),_c('Card',{attrs:{"title":"Hombres","icon":"mars","value":_vm.filteredEmployees.filter(function (employee) { return employee.gender === 'Masculino'; }).length,"total":_vm.filteredEmployees.length,"colors":['var(--main-color-500)', 'var(--main-color-100)']}}),_c('Card',{attrs:{"title":"Mujeres","icon":"venus","value":_vm.filteredEmployees.filter(function (employee) { return employee.gender === 'Femenino'; }).length,"total":_vm.filteredEmployees.length,"colors":['var(--danger-color-400)', 'var(--danger-color-100)']}}),_c('Card',{attrs:{"title":"Media","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(_vm.tableData.map(function (ref) {
            var meanWageGap = ref.meanWageGap;

            return meanWageGap;
})).toFixed(0)) + "%"),"colors":['var(--success-color-500)', 'var(--success-color-100)']}}),_c('Card',{attrs:{"title":"Mediana","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(_vm.tableData.map(function (ref) {
            var medianWageGap = ref.medianWageGap;

            return medianWageGap;
})).toFixed(0)) + "%"),"colors":['var(--success-color-500)', 'var(--success-color-100)']}})],1)]),_c('div',{staticClass:"bottom__container"},[_c('div',[_c('h4',[_vm._v("Nivel laboral")]),_c('div',[_c('date-filter-item',{attrs:{"type":"date","value":_vm.selectedDate,"name":"Hoy día"},on:{"remove":function (e) {
              _vm.selectedDate = null;
              _vm.$emit('filter', _vm.selectedDate);
            },"change":function (e) {
              _vm.selectedDate = e;
              _vm.$emit('filter', _vm.selectedDate);
            }}})],1)]),_c('div',{staticClass:"table__container"},[_c('table',{ref:"table"},[_c('tbody',[_vm._m(0),_vm._m(1),_vm._l((_vm.tableData),function(row,index){return _c('tr',{key:row.categoryOption.id},[_c('td',{staticClass:"number"},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(row.categoryOption.name))]),[_c('td',[_vm._v(" "+_vm._s(row.males.length)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.females.length)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getMean(row.males).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getMean(row.females).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_c('span',{staticClass:"gap__cell",class:row.meanWageGap < 0
                      ? 'gap__cell--female'
                      : row.meanWageGap > 0
                      ? 'gap__cell--male'
                      : 'gap__cell'},[_vm._v(" "+_vm._s(row.meanWageGap.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+"% ")])]),_c('td',[_vm._v(" "+_vm._s((_vm.getMedian(row.males) || 0).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_vm._v(" "+_vm._s((_vm.getMedian(row.females) || 0).toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+" ")]),_c('td',[_c('span',{staticClass:"gap__cell",class:row.medianWageGap < 0
                      ? 'gap__cell--female'
                      : row.medianWageGap > 0
                      ? 'gap__cell--male'
                      : 'gap__cell'},[_vm._v(" "+_vm._s(row.medianWageGap.toLocaleString('en', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, }))+"% ")])]),_c('td',{staticClass:"distribution"},[_c('a',{on:{"click":function($event){return _vm.redirectToPosition(row.categoryOption.id)}}},[_vm._v(" Puestos "),_c('unicon',{attrs:{"name":"angle-right","height":"20","width":"20"}})],1)])]],2)})],2)]),(!Object.keys(_vm.data || {}).length)?_c('div',{staticClass:"employees__empty-state"},[_c('p',[_vm._v("No hay resultados que mostrar con la búsqueda ingresada.")])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{staticClass:"number",attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Categoria")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Hombres")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Mujeres")]),_c('th',{staticClass:"center",attrs:{"colspan":"3"}},[_vm._v("Media")]),_c('th',{staticClass:"center",attrs:{"colspan":"3"}},[_vm._v("Mediana")]),_c('th',{attrs:{"rowspan":"2"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"second__header"},[_c('th',[_vm._v("Hombres")]),_c('th',[_vm._v("Mujeres")]),_c('th',[_vm._v("Brecha salarial")]),_c('th',[_vm._v("Hombres")]),_c('th',[_vm._v("Mujeres")]),_c('th',[_vm._v("Brecha salarial")])])}]

export { render, staticRenderFns }