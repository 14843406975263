<template>
  <div class="container">
    <template v-if="activeFilters.length">
      <span v-for="(filter, index) in activeFilters" :key="filter.id">
        <date-filter-item
          v-if="filter.type === 'date' || filter.type === 'month'"
          :type="filter.type"
          :value="filter.options[0].id"
          :name="filter.name"
          @remove="removeFilter(index)"
          @change="
            (e) => {
              filter.options[0].id = e;
              filter.options[0].name = e;
              filter.options[0].active = !!e;
              $emit('filter', activeFilters);
            }
          "
        />
        <Menu
          v-else-if="filter.type === 'text'"
          direction="below"
          :closeOnItemClick="false"
          @close="$emit('filter', activeFilters)"
        >
          <template #label>
            <filter-item :filter="filter" :type="filter.type" />
          </template>
          <template #beforeOptions v-if="searchBox">
            <div class="input input--text">
              <label>
                <input
                  v-model="filter.searchValue"
                  class="field"
                  type="search"
                  placeholder="Escriba un valor..."
                  @change="
                    (e) => {
                      filter.options[0].id = e.srcElement.value;
                      filter.options[0].name = e.srcElement.value;
                      filter.options[0].active = !!e.srcElement.value;
                    }
                  "
                  @keyup.enter="$emit('filter', activeFilters)"
                />
              </label>
            </div>
          </template>
          <template #options> </template>
          <template #afterOptions>
            <menu-item
              v-if="!filter.isDefault"
              @click="removeFilter(index)"
              :key="`${filter.id}-remover`"
              :isDanger="true"
            >
              Remover
            </menu-item>
          </template>
        </Menu>

        <Menu v-else direction="below" :closeOnItemClick="false">
          <template #label>
            <filter-item :filter="filter" :type="filter.type" />
          </template>
          <template #beforeOptions v-if="searchBox">
            <div class="input">
              <label>
                <input
                  v-model="filter.searchValue"
                  class="field"
                  type="search"
                  placeholder="Buscar opción..."
                  autocomplete="off"
                />
              </label>
            </div>
          </template>
          <template #options>
            <menu-item
              v-for="option in [
                ...(activeFilters
                  .filter(({ options }) => options.filter(({ active }) => active).length)
                  .map(({ id }) => id)
                  .includes(filter.parentId)
                  ? filter.options.filter((option) =>
                      activeFilters
                        .find(({ id }) => id === filter.parentId)
                        .options.filter(({ active }) => active)
                        .map(({ id }) => id)
                        .includes(option.parentId),
                    )
                  : filter.options),
              ]
                .sort((a, b) => Number(b.active) - Number(a.active))
                .filter(
                  (opt) =>
                    !filter.searchValue ||
                    opt.name.toLowerCase().includes(filter.searchValue.toLowerCase()),
                )"
              @click="toggleOption(filter, option)"
              :key="option.id"
            >
              <div class="category__option">
                <span>{{ option.name }}</span>
                <unicon
                  v-if="option.active"
                  width="16px"
                  height="16px"
                  name="check"
                  fill=""
                ></unicon>
              </div>
            </menu-item>
          </template>
          <template #afterOptions>
            <menu-item
              v-if="!filter.isDefault"
              @click="removeFilter(index)"
              :key="`${filter.id}-remover`"
              :isDanger="true"
            >
              Remover
            </menu-item>
          </template>
        </Menu>
      </span>
    </template>

    <Menu v-if="isAddBtnEnable" direction="below">
      <template #label>
        <Button type="button" variant="secondary-text" size="small">
          <unicon width="16px" height="16px" name="plus" fill="#B1B0AE"></unicon>
          Agregar filtro
        </Button>
      </template>
      <template #options>
        <menu-item
          v-for="filter in filters.filter(
            ({ id }) => !activeFilters.map((afilter) => afilter.id).includes(id),
          )"
          :key="filter.id"
          @click="addFilter(filter)"
        >
          <unicon
            v-if="filter.type === 'date' || filter.type === 'month' || filter.type === 'year'"
            name="calender"
            fill="currentColor"
            height="14px"
            width="14px"
          />
          <svg v-else-if="filter.type === 'text'" height="14px" width="14px" fill="currentColor">
            <text
              x="-1"
              y="13px"
              fill="currentColor"
              transform="scale(0.85)"
              style="font-weight: 600"
            >
              Aa
            </text>
          </svg>

          <span class="svg__container" v-else>
            <font-awesome-icon :icon="faCircleChevronDown" size="sm" />
          </span>
          {{ filter.name }}
        </menu-item>
        <p
          v-if="
            !filters.filter(({ id }) => !activeFilters.map((filter) => filter.id).includes(id))
              .length
          "
          class="menu__empty-state"
        >
          Sin resultados
        </p>
      </template>
    </Menu>
  </div>
</template>

<script>
import FilterItem from '@/components/filters/FilterItem.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import Button from '@/components/buttons/Button.vue';
import DateFilterItem from '@/components/filters/DateFilterItem.vue';
import { faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'Filters',
  components: {
    FilterItem,
    Menu,
    MenuItem,
    Button,
    DateFilterItem,
  },

  data() {
    return {
      activeFilters: [],
      faCircleChevronDown,
    };
  },

  props: {
    filters: {
      type: Array,
      // isDefault
      // type
      // isRadio
    },
    filtersOptions: {
      type: Array,
    },
    searchBox: {
      default: true,
    },
    isAddBtnEnable: {
      default: true,
    },
    mountFilters: {
      default: null,
    },
  },

  mounted() {
    this.filters.filter(({ isDefault }) => isDefault).forEach((filter) => this.addFilter(filter));
    if (this.mountFilters) {
      this.activeFilters = this.mountFilters;
      this.$emit('filter', this.activeFilters);
    }
  },

  methods: {
    toggleOption(filter, option) {
      /* eslint-disable */
      const isActive = option.active;
      if (filter.isRadio) filter.options.forEach((opt) => (opt.active = false));
      option.active = !isActive;
      this.$emit('filter', this.activeFilters);
    },

    removeFilter(index) {
      this.activeFilters.splice(index, 1);
      this.$emit('filter', this.activeFilters);
    },
    addFilter(filter) {
      const options = this.filtersOptions
        .filter((option) => option.categoryId === filter.id)
        .map((option) => ({ ...option, active: !!option.active }));
      if (filter.type === 'date' || filter.type === 'month' || filter.type === 'text') {
        // let d = new Date();
        // d = `${d.getFullYear()}-${d.getMonth() < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1}`
        options.push({ id: null, name: '', active: false });
      }
      this.activeFilters.push({
        name: filter.name,
        id: filter.id,
        type: filter.type,
        isDefault: !!filter.isDefault,
        isRadio: !!filter.isRadio,
        parentId: filter.parentId,
        options,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex-wrap: wrap;
}
.input {
  background-color: var(--gray-color-100);
  height: 1.875rem;
  display: flex;
  flex-direction: column;
}

input::placeholder {
  font-size: 0.825rem;
  font-weight: var(--light);
}

.field:focus ~ .label {
  color: var(--main-color-500);
}

.field {
  font-size: 0.825rem;
  max-width: 100%;
  width: 100%;
  padding: 0.375rem 0.625rem;
  color: var(--font-color-700);
  border: 1px solid var(--gray-color-400);
  border-radius: 3.5px;
  transition: border-color 300ms;
  height: 100%;
}

.input--text {
  .field {
    font-size: 0.875rem;
  }
  input::placeholder {
    font-size: 0.875rem;
  }
}

.field:focus {
  border-color: var(--main-color-500);
  box-shadow: var(--main-color-200) 0px 0px 5px;
}

.menu__empty-state {
  padding: 0 0.425rem;
  font-size: 0.8rem;
}

.svg__container {
  width: 14px;
  text-align: left;
}

.category__option {
  width: 100%;
  display: flex;
  gap: 0.625rem;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
