<template>
  <div class="card__container" :style="dataStyles">
    <div>
      <div>
        <h4 class="title">{{ title }}</h4>
        <h2 class="value">{{ value }}</h2>
        <p v-if="subTitle" class="subTitle">{{ subTitle }}</p>
      </div>
      <div>
        <span class="icon" :style="{ backgroundColor: colors[1] }">
          <unicon :name="icon" height="20" width="20" :fill="colors[0]" />
        </span>
        <span class="percent" :style="{ backgroundColor: colors[1] }">
          <p v-if="total" :style="{ color: colors[0] }">
            {{ ((value / total) * 100).toFixed(0) }}%
          </p>
        </span>
      </div>
    </div>
    <div v-if="barValue" class="card__bar">
      <div class="bar__container">
        <span class="bar" :style="{ backgroundColor: colors[0] }"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  components: {},
  props: {
    icon: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    subTitle: {
      required: false,
      type: String,
    },
    value: {
      required: true,
    },
    total: {
      required: false,
    },
    colors: {
      type: Array,
      required: true,
    },
    barValue: {
      default: '',
      required: false,
    },
  },

  computed: {
    dataStyles() {
      return {
        '--bar-length': `${this.barValue}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card__container {
  border: solid 1px var(--gray-color-500);
  border-radius: 3px;
  height: 6rem;
  width: 100%;
  padding: 0.6rem 1rem;
  display: flex;
  flex-flow: column;
  & > div:first-child {
    height: 100%;
    display: flex;
    justify-content: space-between;

    .icon {
      border-radius: 100%;
      padding: 0.3rem;
      width: fit-content;
    }

    .percent {
      border-radius: 3px;
      padding: 0 0.5rem;
      width: fit-content;
      p {
        font-size: 0.7rem;
        line-height: 1.3rem;
      }
    }

    .title,
    .subTitle {
      color: var(--font-color-300);
      font-weight: var(--regular);
    }

    .title {
      font-size: 0.8rem;
      margin-bottom: 0.3rem;
      line-height: 0.8rem;
    }

    .subTitle {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }

    .value {
      font-size: 1.8rem;
    }

    & > div:first-child {
      padding: 0.35rem 0;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
    }

    & > div:nth-child(2) {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  .card__bar {
    padding: 0.5rem 0;
    padding-top: 0.8rem;
    .bar__container {
      display: flex;
      flex-flow: column;
      align-content: center;
      background-color: var(--main-color-100);
      border-radius: 10px;
      .bar {
        height: 0.25rem;
        width: var(--bar-length);
      }
    }
  }
}
</style>
