var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[],"currentView":{ label: 'Brecha salarial', icon: 'money-bill' }}}),_c('h2',[_vm._v("Brecha salarial")])],1),_c('div',{staticClass:"content__buttons"},[_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.openYearsRangeModal}},[_c('unicon',{attrs:{"name":"hourglass","fill":"currentColor","height":"15.5px","width":"15.5px"}}),_vm._v(" Editar rango ")],1),_c('menu-item',{on:{"click":_vm.openExportModal}},[_c('unicon',{attrs:{"name":"file-upload","fill":"currentColor","height":"16px","width":"16px"}}),_vm._v(" Exportar ")],1)]},proxy:true}])}),_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"},on:{"click":_vm.openFormulaModal}},[_c('unicon',{attrs:{"width":"17px","height":"17px","name":"info-circle","fill":"var(--main-color-500)"}})],1)],1)]),_c('div',{staticClass:"filter__container"},[_c('Menu',{attrs:{"direction":"below","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":_vm.incomeFilter}})]},proxy:true},{key:"options",fn:function(){return _vm._l(([].concat( _vm.incomeFilter.options )),function(option){return _c('menu-item',{key:option.id,on:{"click":function($event){option.active = !option.active;
            _vm.filterEmployees();}}},[_vm._v(" "+_vm._s(option.name)+" "),(option.active)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])}),_c('Filters',{ref:"filters",attrs:{"filters":_vm.categories,"filtersOptions":_vm.options},on:{"filter":function (activeFilters) {
          _vm.filters = activeFilters;
          _vm.filterEmployees(activeFilters);
        }}})],1),(!_vm.isLoading)?_c('Tabs',{attrs:{"tabList":[
      { title: 'Board', icon: 'table' },
      { title: 'Dashboard', icon: 'apps' } ]},on:{"tabChange":_vm.onTabChange},scopedSlots:_vm._u([{key:"tabPanel-1",fn:function(){return [_c('work-level',{ref:"work-level",attrs:{"filteredEmployees":_vm.filteredEmployees,"tableData":_vm.tableData,"data":_vm.data,"selectedDate":_vm.selectedDate,"filters":_vm.filters,"incomeFilter":_vm.incomeFilter},on:{"filter":function (e) {
            _vm.selectedDate = e;
            _vm.filterEmployees();
          }}})]},proxy:true},{key:"tabPanel-2",fn:function(){return [(_vm.filteredEmployees.length)?_c('dashboard',{ref:"dashboard",attrs:{"tableData":_vm.tableData,"filteredEmployees":_vm.filteredEmployees,"yearsRange":_vm.yearsRange,"filters":_vm.filters}}):_vm._e()]},proxy:true}],null,false,690282402)}):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e(),_c('years-range-modal',{ref:"modal__yearsRange"}),_c('export-modal',{ref:"modal__export",attrs:{"formats":{
      ppt: { id: 'ppt', name: 'PPT', options: [{ id: 'dashboard', name: 'Dashboard' }] },
      excel: {
        id: 'excel',
        name: 'Excel',
        options: [
          { id: 'board', name: 'Nivel laboral' },
          { id: 'dashboard', name: 'Brecha salarial en el tiempo' },
          { id: 'positions', name: 'Puestos' },
          { id: 'ranges', name: 'Rangos' } ],
      },
    }},on:{"export":function($event){return _vm.onExport($event)}}}),_c('Modal',{ref:"modal__formula",attrs:{"size":"free"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Formula Brecha salarial")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"formula__modal"},[_c('p',[_vm._v("BRECHA SALARIAL")]),_c('p',[_vm._v("=")]),_c('div',[_c('p',[_c('span',[_vm._v("Retribución media anual hombres")]),_vm._v(" - "),_c('span',[_vm._v("Retribución media anual mujeres")])]),_c('p',[_vm._v("Retribución media anual hombres")])]),_c('p',[_vm._v("X")]),_c('p',[_vm._v("100")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }