var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Doughnut',{attrs:{"chart-data":_vm.chartData,"chart-options":Object.assign({}, _vm.chartOptions,
    {responsive: true,
    maintainAspectRatio: false,
    plugins: Object.assign({}, _vm.chartOptions.plugins,

      {legend: Object.assign({}, (_vm.chartOptions.plugins.legend || {}),
        {labels: Object.assign({}, (_vm.chartOptions.plugins.legend ? _vm.chartOptions.plugins.legend.labels : {}),
          {boxWidth: 10,
          boxHeight: 9,
          color: '#9096a5',
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          }})}),
      datalabels: Object.assign({}, (_vm.chartOptions.plugins.datalabels || {}),
        {font: {
          size: 12,
          weight: 500,
          family: 'Inter',
        },
        formatter: function (value, context) {
          return value ? Math.round(value * 100) + '%' : '';
        },
        color: '#fff'}),
      tooltip: Object.assign({}, (_vm.chartOptions.plugins.tooltip || {}),
        {xAlign: 'center',
        yAlign: 'bottom',
        bodyAlign: 'left',
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 5,
        displayColors: false,
        titleFont: {
          size: 11,
          weight: 600,
          family: 'Inter',
        },
        bodyFont: {
          size: 11,
          weight: 500,
          family: 'Inter',
        },
        bodySpacing: 3,
        padding: {
          left: 12,
          right: 16,
          top: 11,
          bottom: 11,
        }})})}),"option":_vm.chartOptions,"width":_vm.width,"height":_vm.height}})}
var staticRenderFns = []

export { render, staticRenderFns }