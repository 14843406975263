var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LineJS',{attrs:{"chart-data":_vm.chartData,"chart-options":Object.assign({}, _vm.chartOptions,
    {responsive: true,
    maintainAspectRatio: false,
    scales: Object.assign({}, (_vm.chartOptions.scales || {}),
      ( _obj = {}, _obj['y'] = Object.assign({}, (_vm.chartOptions.scales ? _vm.chartOptions.scales['y'] : {}),
        // max: 1,
        {ticks: {
          // steps: 5,
          // stepSize: 0.2,
          color: '#9096a5',
          font: {
            size: 11,
            weight: 500,
            family: 'Inter',
          },
          callback: function (value) {
            return parseFloat((value * 100).toFixed(2)) + '%';
          },
        }}), _obj['x'] = Object.assign({}, (_vm.chartOptions.scales ? _vm.chartOptions.scales[_vm.chartOptions.indexAxis] : {}),
        {ticks: Object.assign({}, (_vm.chartOptions.scales ? _vm.chartOptions.scales[_vm.chartOptions.indexAxis].ticks : {}),
          {color: '#9096a5',
          font: {
            size: 11,
            weight: 500,
            family: 'Inter',
          }})}), _obj )),
    plugins: Object.assign({}, _vm.chartOptions.plugins,
      {legend: Object.assign({}, (_vm.chartOptions.plugins.legend || {}),
        {labels: Object.assign({}, (_vm.chartOptions.plugins.legend ? _vm.chartOptions.plugins.legend.labels : {}),
          {boxWidth: 10,
          boxHeight: 9,
          color: '#9096a5',
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          }})}),
      datalabels: {
        display: false,
      },
      tooltip: Object.assign({}, (_vm.chartOptions.plugins.tooltip || {}),
        {bodyAlign: 'left',
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 5,
        displayColors: true,
        titleFont: {
          size: 11,
          weight: 800,
          family: 'Inter',
        },
        bodyFont: {
          size: 11,
          weight: 500,
          family: 'Inter',
        },
        bodySpacing: 3,
        padding: {
          left: 12,
          right: 16,
          top: 11,
          bottom: 11,
        }})})}),"option":_vm.chartOptions,"width":_vm.width,"height":_vm.height}})}
var staticRenderFns = []

export { render, staticRenderFns }