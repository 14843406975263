var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content"},[_c('Card',{staticClass:"card__section",attrs:{"title":"Hombres","icon":"mars","value":_vm.maleEmployees,"total":_vm.filteredEmployees.length,"barValue":(_vm.maleEmployees / _vm.filteredEmployees.length) * 100,"colors":['#5382F0', '#D7E6FF']}}),_c('Card',{staticClass:"card__section",attrs:{"title":"Mujeres","icon":"venus","value":_vm.femaleEmployees,"total":_vm.filteredEmployees.length,"barValue":(_vm.femaleEmployees / _vm.filteredEmployees.length) * 100,"colors":['#FE6695', '#FEDDDC']}}),_c('Card',{staticClass:"card__section",attrs:{"title":"Media","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(_vm.tableData.map(function (ref) {
	var meanWageGap = ref.meanWageGap;

	return meanWageGap;
}))) + "%"),"barValue":_vm.getMean(_vm.tableData.map(function (ref) {
	var meanWageGap = ref.meanWageGap;

	return meanWageGap;
})),"colors":['#70B5C0', '#e3fcec']}}),_c('Card',{staticClass:"card__section",attrs:{"title":"Mediana","subTitle":"Brecha salarial","icon":"money-bill","value":((_vm.getMean(_vm.tableData.map(function (ref) {
	var medianWageGap = ref.medianWageGap;

	return medianWageGap;
}))) + "%"),"barValue":_vm.getMean(_vm.tableData.map(function (ref) {
	var medianWageGap = ref.medianWageGap;

	return medianWageGap;
})),"colors":['#70B5C0', '#e3fcec']}}),_c('position-doughnut-chart',{ref:"position-chart",attrs:{"filteredEmployees":_vm.filteredEmployees}}),_c('category-bar-chart',{ref:"category-chart",attrs:{"filteredEmployees":_vm.filteredEmployees,"tableData":_vm.tableData}}),_c('wage-gap-line-chart',{ref:"wageGap-chart",attrs:{"filteredEmployees":_vm.filteredEmployees,"data":_vm.data,"yearsFilter":_vm.yearsFilter,"months":_vm.months}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }