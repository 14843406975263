<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Brecha salarial', icon: 'money-bill' }"
        />
        <h2>Brecha salarial</h2>
      </div>
      <div class="content__buttons">
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="openYearsRangeModal">
              <unicon name="hourglass" fill="currentColor" height="15.5px" width="15.5px"></unicon>
              Editar rango
            </menu-item>
            <menu-item @click="openExportModal">
              <unicon
                name="file-upload"
                fill="currentColor"
                height="16px"
                width="16px"
              ></unicon>
              Exportar
            </menu-item>
          </template>
        </Menu>

        <Button
          type="button"
          variant="secondary"
          size="xsmall"
          @click="openFormulaModal"
        >
          <unicon
            width="17px"
            height="17px"
            name="info-circle"
            fill="var(--main-color-500)"
          ></unicon>
        </Button>
      </div>
    </div>

    <div class="filter__container">
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item :filter="incomeFilter" />
        </template>
        <template #options>
          <menu-item
            v-for="option in [...incomeFilter.options]"
            @click="
              option.active = !option.active;
              filterEmployees();
            "
            :key="option.id"
          >
            {{ option.name }}
            <unicon
              v-if="option.active"
              width="16px"
              height="16px"
              name="check"
              fill=""
            ></unicon>
          </menu-item>
        </template>
      </Menu>
      <Filters
        @filter="
          (activeFilters) => {
            filters = activeFilters;
            filterEmployees(activeFilters);
          }
        "
        :filters="categories"
        :filtersOptions="options"
        ref="filters"
      />
    </div>
    <Tabs
      v-if="!isLoading"
      :tabList="[
        { title: 'Board', icon: 'table' },
        { title: 'Dashboard', icon: 'apps' },
      ]"
      @tabChange="onTabChange"
    >
      <template v-slot:tabPanel-1>
        <work-level
          ref="work-level"
          :filteredEmployees="filteredEmployees"
          :tableData="tableData"
          :data="data"
          :selectedDate="selectedDate"
          :filters="filters"
          :incomeFilter="incomeFilter"
          @filter="
            (e) => {
              selectedDate = e;
              filterEmployees();
            }
          "
        />
      </template>
      <template v-slot:tabPanel-2>
        <dashboard
          ref="dashboard"
          v-if="filteredEmployees.length"
          :tableData="tableData"
          :filteredEmployees="filteredEmployees"
          :yearsRange="yearsRange"
          :filters="filters"
        />
      </template>
    </Tabs>
    <div v-if="isLoading" class="loading"><loading-spinner /></div>

    <years-range-modal ref="modal__yearsRange" />

    <export-modal
      ref="modal__export"
      @export="onExport($event)"
      :formats="{
        ppt: { id: 'ppt', name: 'PPT', options: [{ id: 'dashboard', name: 'Dashboard' }] },
        excel: {
          id: 'excel',
          name: 'Excel',
          options: [
            { id: 'board', name: 'Nivel laboral' },
            { id: 'dashboard', name: 'Brecha salarial en el tiempo' },
            { id: 'positions', name: 'Puestos' },
            { id: 'ranges', name: 'Rangos' },
          ],
        },
      }"
    />

    <Modal size="free" ref="modal__formula">
      <template #title>Formula Brecha salarial</template>
      <template #content>
        <div class="formula__modal">
          <p>BRECHA SALARIAL</p>
          <p>=</p>
          <div>
            <p>
              <span>Retribución media anual hombres</span> -
              <span>Retribución media anual mujeres</span>
            </p>
            <p>Retribución media anual hombres</p>
          </div>
          <p>X</p>
          <p>100</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import { faFilePowerpoint, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Modal from '@/components/Modal.vue';
import Button from '@/components/buttons/Button.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Filters from '@/components/filters/Filters.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import Tabs from '@/components/Tabs.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import ExportModal from '@/components/ExportModal.vue';
import { YMDToTimeStamp } from '@/dateFormats';
import * as XLSX from 'xlsx';
import YearsRangeModal from './modals/YearsRangeModal.vue';
import WorkLevel from './WorkLevel.vue';
import Dashboard from './Dashboard.vue';

export default {
  components: {
    Menu,
    MenuItem,
    FilterItem,
    Button,
    Breadcrumbs,
    LoadingSpinner,
    Filters,
    YearsRangeModal,
    Tabs,
    WorkLevel,
    Dashboard,
    ExportModal,
    Modal,
  },
  data() {
    return {
      YMDToTimeStamp,
      isLoading: true,
      icons: { ppt: faFilePowerpoint, excel: faFileExcel },
      filteredEmployees: [],
      filters: [],
      selectedDate: null,
      incomeFilter: {
        id: 'incomeId',
        name: 'Ingresos',
        options: [
          {
            id: 'miniumWage', name: 'Sueldo base', active: true, index: 0,
          },
          {
            id: 'otherIncomes', name: 'Otros ingresos', active: false, index: 1,
          },
        ],
      },
    };
  },

  async mounted() {
    try {
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      this.filterEmployees();
    } catch (error) {
      console.log(error);
      this.setAlert({
        state: 'error',
        message: 'Ocurrió un error al cargar los datos, por favor inténtelo nuevamente',
      });
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    ...mapActions('employees', ['fetchEmployees', 'batchDelete']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    ...mapMutations(['setAlert']),

    openFormulaModal() {
      this.$refs.modal__formula.open();
    },
    downloadExcelPositions() {
      console.log(this.data);
      const table = [];
      table.push({
        Categoria: '',
        Puesto: '',
        Hombres: '',
        Mujeres: '',
        Media: 'Hombres',
        Media2: 'Mujeres',
        Media3: 'Brecha Salarial',
        Mediana: 'Hombres',
        Mediana2: 'Mujeres',
        Mediana3: 'Brecha Salarial',
      });
      Object.keys(this.data).forEach((categoryId) => {
        Object.keys(this.data[categoryId]).forEach((positionId) => {
          const row = {};
          row.Categoria = this.options.find(({ id }) => categoryId === id).name;
          row.Puesto = this.options.find(({ id }) => positionId === id).name;
          const males = Object.entries(this.data[categoryId][positionId])
            .map((entry) => entry[1])
            .flat(1)
            .filter((employee) => employee.gender === 'Masculino')
            .map((employee) => employee.miniumWage);
          const females = Object.entries(this.data[categoryId][positionId])
            .map((entry) => entry[1])
            .flat(1)
            .filter((employee) => employee.gender === 'Femenino')
            .map((employee) => employee.miniumWage);
          row.Hombres = males.length;
          row.Mujeres = females.length;
          row.Media = this.getMean(males) || 0;
          row.Media2 = this.getMean(females) || 0;
          row.Media3 = this.positionMedianWageGap(this.data[categoryId][positionId]);
          row.Mediana = this.getMedian(males) || 0;
          row.Mediana2 = this.getMedian(females) || 0;
          row.Mediana3 = this.positionMeanWageGap(this.data[categoryId][positionId]);
          table.push(row);
        });
      });
      const worksheet = XLSX.utils.json_to_sheet(table);
      const merge = [
        {
          e: { r: 1, c: 0 },
          s: { r: 0, c: 0 },
        },
        {
          e: { r: 1, c: 1 },
          s: { r: 0, c: 1 },
        },
        {
          e: { r: 1, c: 2 },
          s: { r: 0, c: 2 },
        },
        {
          e: { r: 1, c: 3 },
          s: { r: 0, c: 3 },
        },
        {
          e: { r: 0, c: 6 },
          s: { r: 0, c: 4 },
        },
        {
          e: { r: 0, c: 9 },
          s: { r: 0, c: 7 },
        },
        {
          e: { r: 1, c: 10 },
          s: { r: 0, c: 10 },
        },
      ];
      worksheet['!merges'] = merge;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Empleados');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },

    downloadExcelRanges() {
      console.log(this.data);
      const table = [];
      table.push({
        Categoria: '',
        Puesto: '',
        Rango: '',
        Hombres: '',
        Mujeres: '',
        Media: 'Hombres',
        Media2: 'Mujeres',
        Media3: 'Brecha Salarial',
        Mediana: 'Hombres',
        Mediana2: 'Mujeres',
        Mediana3: 'Brecha Salarial',
      });
      Object.keys(this.data).forEach((categoryId) => {
        Object.keys(this.data[categoryId]).forEach((positionId) => {
          Object.keys(this.data[categoryId][positionId])
            .sort((a, b) => (parseFloat(a.split('-')[0]) > parseFloat(b.split('-')[0]) ? 1 : -1))
            .forEach((range) => {
              const row = {};
              row.Categoria = this.options.find(({ id }) => categoryId === id).name;
              row.Puesto = this.options.find(({ id }) => positionId === id).name;
              row.Rango = range;
              const males = this.data[categoryId][positionId][range]
                .filter((employee) => employee.gender === 'Masculino')
                .map((employee) => employee.miniumWage);
              const females = this.data[categoryId][positionId][range]
                .filter((employee) => employee.gender === 'Femenino')
                .map((employee) => employee.miniumWage);
              const maleMedian = this.getMedian(males) || 0;
              const femaleMedian = this.getMedian(females) || 0;
              const maleMean = this.getMean(males) || 0;
              const femaleMean = this.getMean(females) || 0;
              row.Hombres = males.length;
              row.Mujeres = females.length;
              row.Media = maleMean;
              row.Media2 = femaleMean;
              row.Media3 = this.wageGap(maleMean, femaleMean);
              row.Mediana = maleMedian;
              row.Mediana2 = femaleMedian;
              row.Mediana3 = this.wageGap(maleMedian, femaleMedian);
              table.push(row);
            });
        });
      });
      const worksheet = XLSX.utils.json_to_sheet(table);
      const merge = [
        {
          e: { r: 1, c: 0 },
          s: { r: 0, c: 0 },
        },
        {
          e: { r: 1, c: 1 },
          s: { r: 0, c: 1 },
        },
        {
          e: { r: 1, c: 2 },
          s: { r: 0, c: 2 },
        },
        {
          e: { r: 1, c: 3 },
          s: { r: 0, c: 3 },
        },
        {
          e: { r: 1, c: 4 },
          s: { r: 0, c: 4 },
        },
        {
          e: { r: 0, c: 7 },
          s: { r: 0, c: 5 },
        },
        {
          e: { r: 0, c: 10 },
          s: { r: 0, c: 8 },
        },
        {
          e: { r: 1, c: 11 },
          s: { r: 0, c: 11 },
        },
      ];
      worksheet['!merges'] = merge;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Empleados');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },

    onExport(exportConfig) {
      if (exportConfig.format === 'ppt') this.$refs.dashboard.downloadPPT();
      if (exportConfig.format === 'excel') {
        if (exportConfig.content === 'dashboard') this.$refs.dashboard.downloadExcel();
        if (exportConfig.content === 'board') this.$refs['work-level'].downloadExcel();
        if (exportConfig.content === 'positions') this.downloadExcelPositions();
        if (exportConfig.content === 'ranges') this.downloadExcelRanges();
      }
    },

    onTabChange() {
      this.selectedDate = null;
      this.$refs['work-level'].selectedDate = null;
      this.filterEmployees(this.filters);
    },

    openExportModal() {
      this.$refs.modal__export.open();
    },

    openYearsRangeModal() {
      this.$refs.modal__yearsRange.open();
    },

    categoryMeanWageGap(positions) {
      return this.getMean(
        Object.keys(positions).map((key) => this.positionMeanWageGap(positions[key])),
      );
    },

    positionMeanWageGap(entryYears) {
      let wageGapCont = 0;
      Object.keys(entryYears).forEach((key) => {
        wageGapCont += Math.max(
          this.wageGap(
            this.getMean(
              entryYears[key]
                .filter((employee) => employee.gender === 'Masculino')
                .map((employee) => employee.miniumWage),
            ),
            this.getMean(
              entryYears[key]
                .filter((employee) => employee.gender === 'Femenino')
                .map((employee) => employee.miniumWage),
            ),
          ),
          0,
        );
      });
      return parseFloat((wageGapCont / Object.keys(entryYears).length || 0).toFixed(1));
    },

    categoryMedianWageGap(positions) {
      return this.getMean(
        Object.keys(positions).map((key) => this.positionMedianWageGap(positions[key])),
      );
    },

    positionMedianWageGap(entryYears) {
      let wageGapCont = 0;
      Object.keys(entryYears).forEach((key) => {
        wageGapCont += Math.max(
          this.wageGap(
            this.getMedian(
              entryYears[key]
                .filter((employee) => employee.gender === 'Masculino')
                .map((employee) => employee.miniumWage),
            ),
            this.getMedian(
              entryYears[key]
                .filter((employee) => employee.gender === 'Femenino')
                .map((employee) => employee.miniumWage),
            ),
          ),
          0,
        );
      });
      return parseFloat((wageGapCont / Object.keys(entryYears).length || 0).toFixed(1));
    },

    getMedian(arr) {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },

    getMean(arr) {
      return parseFloat(arr.reduce((p, c, i) => p + (c - p) / (i + 1), 0).toFixed(1), 10);
    },

    wageGap(malesTotal, femalesTotal) {
      if (malesTotal === 0 || femalesTotal === 0) return 0;
      return parseFloat((((malesTotal - femalesTotal) / malesTotal) * 100 || 0).toFixed(1));
    },

    filterEmployees(filters = []) {
      const activeFilters = [];
      const employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            options: activeOptions,
          });
        }
      });
      this.filteredEmployees = employees.filter((employee) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          if (!filter.options.includes(employee[filter.id])) {
            return false;
          }
        }
        return true;
      });

      const currTime = this.selectedDate
        ? this.YMDToTimeStamp(this.selectedDate).seconds
        : new Date().getTime() / 1000;
      this.filteredEmployees = this.filteredEmployees.filter(({ entryDate, terminationDate }) => {
        const terminated = terminationDate ? terminationDate.seconds < currTime : false;
        return entryDate.seconds <= currTime && !terminated;
      });

      this.filteredEmployees = this.filteredEmployees.map((employee) => {
        const currEmployee = { ...employee };
        this.categories.forEach(({ id }) => {
          currEmployee[id] = this.getEmployeeValue(currEmployee, id);
        });
        currEmployee.miniumWage = this.getTotalIncome(
          this.getEmployeeValue(currEmployee, 'miniumWage'),
          this.getEmployeeValue(currEmployee, 'otherIncomes'),
        );
        currEmployee.otherIncomes = this.getEmployeeValue(currEmployee, 'otherIncomes');
        return currEmployee;
      });
    },

    getTotalIncome(miniumWage, otherIncomes) {
      const accOtherIncomes = otherIncomes.reduce((acc, val) => acc + val, 0);
      const activeIncomes = this.incomeFilter.options
        .filter(({ active }) => active)
        .map(({ id }) => id);
      if (activeIncomes.length === 0) return 0;
      if (activeIncomes.length === 2) return accOtherIncomes + miniumWage;
      if (activeIncomes[0] === 'miniumWage') return miniumWage;
      return accOtherIncomes;
    },

    getRangePosition(timeStamp) {
      const d = new Date(timeStamp.seconds * 1000);
      const today = new Date();
      const age = today.getFullYear() - d.getFullYear();
      let selectedRange = -1;
      this.yearsRange.forEach((range) => {
        if (range.from < age && range.to >= age) selectedRange = `${range.from}-${range.to}`;
      });
      return selectedRange;
    },

    getEmploymentAge(timeStamp) {
      const d = new Date(timeStamp.seconds * 1000);
      const today = new Date();
      const age = today.getFullYear() - d.getFullYear();
      return age;
    },

    getEmployeeValue(employee, categoryId) {
      if (!this.selectedDate) return employee[categoryId];
      if (!employee.history[categoryId]) return null;
      const currTime = this.YMDToTimeStamp(this.selectedDate).seconds;
      let resultIndex = -1;
      const some = Object.keys(employee.history[categoryId])
        .sort((a, b) => (a > b ? 1 : -1))
        .some((timestamp, index) => {
          resultIndex = index;
          return timestamp > currTime;
        });
      if (some) {
        return employee.history[categoryId][
          Object.keys(employee.history[categoryId]).sort((a, b) => (a > b ? 1 : -1))[
            resultIndex - 1
          ]
        ];
      }
      return employee[categoryId];
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, yearsRange: state.company.yearsRange || 10 }),
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    yearsRange() {
      const result = [];
      for (
        let index = 0;
        index
        <= Math.max(...this.employees.map(({ entryDate }) => this.getEmploymentAge(entryDate)));
        index += this.company.yearsRange
      ) {
        result.push({ from: index, to: index + this.company.yearsRange });
      }
      return result;
    },

    tableData() {
      const result = [];
      Object.keys(this.data || {}).forEach((categoryId) => {
        result.push({
          categoryOption: this.options.find(({ id }) => id === categoryId),
          males: Object.entries(this.data[categoryId])
            .map((entry) => Object.entries(entry[1]).map((entry2) => entry2[1]))
            .flat(1)
            .flat(1)
            .filter((employee) => employee.gender === 'Masculino')
            .map((employee) => employee.miniumWage),
          females: Object.entries(this.data[categoryId])
            .map((entry) => Object.entries(entry[1]).map((entry2) => entry2[1]))
            .flat(1)
            .flat(1)
            .filter((employee) => employee.gender === 'Femenino')
            .map((employee) => employee.miniumWage),
          medianWageGap: this.categoryMedianWageGap(this.data[categoryId]),
          meanWageGap: this.categoryMeanWageGap(this.data[categoryId]),
        });
      });

      return result;
    },

    data() {
      const data = {};
      const category = this.categories.find((cat) => cat.id === 'nivellaboral');
      const position = this.categories.find((cat) => cat.id === 'puesto');
      const categoryOptions = this.options.filter(({ categoryId }) => categoryId === category.id);
      const positionOptions = this.options.filter(({ categoryId }) => categoryId === position.id);
      categoryOptions.forEach(({ id }) => {
        data[id] = {};
        positionOptions.forEach((option) => {
          data[id][option.id] = {};
        });
      });
      this.filteredEmployees.forEach((employee) => {
        const categoryValue = employee[category.id];
        const positionValue = employee[position.id];
        if (data[categoryValue] && data[categoryValue][positionValue]) {
          if (!data[categoryValue][positionValue][this.getRangePosition(employee.entryDate)]) {
            data[categoryValue][positionValue][this.getRangePosition(employee.entryDate)] = [];
          }
          data[categoryValue][positionValue][this.getRangePosition(employee.entryDate)].push(
            employee,
          );
        }
      });
      const result = {};
      Object.keys(data).forEach((key) => {
        result[key] = {};
        Object.keys(data[key]).forEach((key2) => {
          if (Object.keys(data[key][key2]).length) result[key][key2] = data[key][key2];
        });
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  flex-grow: 1;
  height: calc(100% - 40.8px - 26.55px - 1rem - 0.3rem - 35px - 1rem) !important;
}
.view__container {
  border-radius: 10px;
  // background-color: #F6F9FF;
  display: flex;
  flex-flow: column;
  padding-bottom: 0.3rem;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}

.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.excel__btn {
  font-size: 14px;
  width: 16px;
}

.ppt__btn {
  font-size: 14px;
  width: 16px;
}

.loading {
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
}

.formula__modal {
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: .5rem;
  p {
    line-height: 1.1rem;
    color: var(--font-color-700);
  }
  div {
    display: flex;
    flex-flow: column;
    align-items: center;
    p {
      span:first-child {
        color: var(--main-color-500);
      }
      span:nth-child(2) {
        color: var(--danger-color-300);
      }
      &:first-child {
        border-bottom: solid 1px var(--font-color-700);
        padding-bottom: .1rem;
      }
      &:last-child  {
        color: var(--main-color-500);
      }
    }
  }
}
</style>
