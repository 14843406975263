var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Bar',{attrs:{"chart-data":_vm.chartData,"chart-options":Object.assign({}, _vm.chartOptions,
    {responsive: true,
    maintainAspectRatio: false,
    borderRadius: 4,
    scales: Object.assign({}, (_vm.chartOptions.scales || {}),
      ( _obj = {}, _obj[_vm.chartOptions.indexAxis === 'y' ? 'x' : 'y'] = Object.assign({}, (_vm.chartOptions.scales
          ? _vm.chartOptions.scales[_vm.chartOptions.indexAxis === 'y' ? 'x' : 'y']
          : {}),
        {max: _vm.chartOptions.max,
        ticks: {
          steps: _vm.chartOptions.steps,
          stepSize: _vm.chartOptions.stepSize,
          color: '#9096a5',
          font: {
            size: 11,
            weight: 500,
            family: 'Inter',
          },
          callback: function (value) {
            return Math.round(value * 100) + '%';
          },
        }}), _obj[_vm.chartOptions.indexAxis] = Object.assign({}, (_vm.chartOptions.scales ? _vm.chartOptions.scales[_vm.chartOptions.indexAxis] : {}),
        {ticks: Object.assign({}, (_vm.chartOptions.scales ? _vm.chartOptions.scales[_vm.chartOptions.indexAxis].ticks : {}),
          {color: '#9096a5',
          font: {
            size: 11,
            weight: 500,
            family: 'Inter',
          }})}), _obj )),
    plugins: Object.assign({}, _vm.chartOptions.plugins,
      {legend: Object.assign({}, (_vm.chartOptions.plugins.legend || {}),
        {labels: Object.assign({}, (_vm.chartOptions.plugins.legend ? _vm.chartOptions.plugins.legend.labels : {}),
          {boxWidth: 10,
          boxHeight: 9,
          color: '#9096a5',
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          }})}),
      datalabels: Object.assign({}, {font: {
          size: 12,
          weight: 500,
          family: 'Inter',
        },
        formatter: function (value, context) {
          return value ? (value * 100).toFixed(0) + '%' : '';
        },
        color: '#fff'},
        _vm.chartOptions.plugins.datalabels),
      tooltip: Object.assign({}, (_vm.chartOptions.plugins.tooltip || {}),
        {bodyAlign: 'left',
        boxWidth: 10,
        boxHeight: 10,
        boxPadding: 5,
        displayColors: false,
        titleFont: {
          size: 11,
          weight: 800,
          family: 'Inter',
        },
        bodyFont: {
          size: 11,
          weight: 500,
          family: 'Inter',
        },
        bodySpacing: 3,
        padding: {
          left: 12,
          right: 16,
          top: 11,
          bottom: 11,
        }})})}),"option":_vm.chartOptions,"width":_vm.width,"height":_vm.height}})}
var staticRenderFns = []

export { render, staticRenderFns }