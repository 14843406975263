<template>
  <div class="view__container">
    <div class="content">
      <Card
        class="card__section"
        title="Hombres"
        icon="mars"
        :value="maleEmployees"
        :total="filteredEmployees.length"
        :barValue="(maleEmployees / filteredEmployees.length) * 100"
        :colors="['#5382F0', '#D7E6FF']"
      />
      <Card
        class="card__section"
        title="Mujeres"
        icon="venus"
        :value="femaleEmployees"
        :total="filteredEmployees.length"
        :barValue="(femaleEmployees / filteredEmployees.length) * 100"
        :colors="['#FE6695', '#FEDDDC']"
      />
      <Card
        class="card__section"
        title="Media"
        subTitle="Brecha salarial"
        icon="money-bill"
        :value="`${getMean(tableData.map(({ meanWageGap }) => meanWageGap))}%`"
        :barValue="getMean(tableData.map(({ meanWageGap }) => meanWageGap))"
        :colors="['#70B5C0', '#e3fcec']"
      />
      <Card
        class="card__section"
        title="Mediana"
        subTitle="Brecha salarial"
        icon="money-bill"
        :value="`${getMean(tableData.map(({ medianWageGap }) => medianWageGap))}%`"
        :barValue="getMean(tableData.map(({ medianWageGap }) => medianWageGap))"
        :colors="['#70B5C0', '#e3fcec']"
      />

      <position-doughnut-chart ref="position-chart" :filteredEmployees="filteredEmployees" />

      <category-bar-chart
        ref="category-chart"
        :filteredEmployees="filteredEmployees"
        :tableData="tableData"
      />

      <wage-gap-line-chart
        ref="wageGap-chart"
        :filteredEmployees="filteredEmployees"
        :data="data"
        :yearsFilter="yearsFilter"
        :months="months"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { YMDToTimeStamp } from '@/dateFormats';
import Card from '@/components/Card.vue';
import pptxgen from 'pptxgenjs';
import * as XLSX from 'xlsx';
import WageGapLineChart from './WageGapLineChart.vue';
import PositionDoughnutChart from './PositionDoughnutChart.vue';
import CategoryBarChart from './CategoryBarChart.vue';

export default {
  components: {
    Card,
    WageGapLineChart,
    PositionDoughnutChart,
    CategoryBarChart,
  },
  data() {
    return {
      YMDToTimeStamp,
      yearsFilter: {
        id: 'yearsId',
        name: 'Año',
        options: [],
      },
      months: [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ],
    };
  },

  props: {
    filteredEmployees: {
      type: Array,
    },
    yearsRange: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    filters: {
      type: Array,
    },
  },

  methods: {
    downloadExcel() {
      const mediaData = [];
      const medianData = [];
      const { chartDataMonths, chartDataYears } = this.$refs['wageGap-chart'];
      chartDataYears.labels.forEach((year, i) => {
        const tableMedia = { Año: year };
        const tableMedian = { Año: year };
        chartDataMonths[year].labels.forEach((month, j) => {
          tableMedia[month] = chartDataMonths[year].datasets[0].data[j];
          tableMedian[month] = chartDataMonths[year].datasets[1].data[j];
        });
        tableMedia.Total = chartDataYears.datasets[0].data[i];
        tableMedian.Total = chartDataYears.datasets[1].data[i];
        mediaData.push(tableMedia);
        medianData.push(tableMedian);
      });
      console.log(mediaData);
      const workbook = XLSX.utils.book_new();
      const worksheetMedia = XLSX.utils.json_to_sheet(mediaData);
      const worksheetMedian = XLSX.utils.json_to_sheet(medianData);
      XLSX.utils.book_append_sheet(workbook, worksheetMedia, 'Media en el tiempo');
      XLSX.utils.book_append_sheet(workbook, worksheetMedian, 'Mediana en el tiempo');
      XLSX.writeFile(workbook, 'reporte.xlsx');
    },

    getPPTCard(title, value, barValue, color) {
      const border = { pt: '2', color };
      return [
        [
          {
            text: title,
            options: {
              border: [border, null, null, border],
              margin: [0.12, 0.12, 0, 0.12],
              fontSize: 8,
            },
          },
          {
            options: {
              border: [border, border, null, border],
              margin: [0, 0.12, 0.12, 0.12],
              fontSize: 8,
            },
          },
        ],
        [
          {
            text: value,
            options: {
              border: [null, null, border, border],
              margin: [0, 0.12, 0.12, 0.12],
              bold: true,
            },
          },
          {
            text: `${barValue}%`,
            options: {
              border: [null, border, border, border],
              margin: [0.05, 0, 0.24, 0.4],
              fontSize: 7.5,
            },
          },
        ],
      ];
    },

    downloadPPT() {
      const chartsData = [];
      chartsData.push(this.$refs['position-chart'].genderData);
      chartsData.push(this.$refs['category-chart'].categoryData);
      const $wageGapChart = this.$refs['wageGap-chart'];
      chartsData.push(
        $wageGapChart.lineChartView === 'months'
          ? $wageGapChart.chartDataMonths[
            parseInt(this.yearsFilter.options.find(({ active }) => active).name, 10)
          ]
          : $wageGapChart.chartDataYears,
      );

      /* eslint-disable */
      const pptx = new pptxgen();

      let slide = pptx.addSlide();

      slide.addText('Equidad salarial', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      this.filters.forEach((filter, i) => {
        let options = filter.options.filter((option) => option.active);
        text += options.length ? ' / ' + filter.name + ': ' : '';
        options.forEach((option, index) => {
          text += option.name + (index == options.length - 1 ? '' : ', ');
        });
        text += text.length && i === this.filters.length - 1 ? ' /' : '';
      });
      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });

      const config = {
        x: 1,
        w: 8,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: [...this.company.colors.map((color) => color.slice(1)), '70B5C0'],
        dataLabelPosition: 't',
        dataLabelFormatCode: '##.##%',
        valAxisLabelFormatCode: '##.##%',
        showValue: true,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
      };

      chartsData.forEach((chart, i) => {
        const slide = pptx.addSlide();
        const chartData = [];
        chart.datasets.forEach((data, j) => {
          chartData.push({
            name: j === 0 ? 'Media' : 'Mediana',
            labels: chart.labels.map((val) => (Array.isArray(val) ? val.join(' ') : val)),
            values: data.data,
          });
        });
        if (i === 0) {
          const config2 = {
            w: 1.5,
            h: 0.35,
            showValue: false,
            showLegend: false,
            barDir: 'bar',
            barGrouping: 'stacked',
            catGridLine: { style: 'none' },
            valGridLine: { style: 'none' },
            catAxisHidden: true,
            valAxisHidden: true,
            valAxisMaxVal: 1,
          };
          const titles = ['Hombres', 'Mujeres', 'Media', 'Mediana'];
          const values = [
            this.maleEmployees,
            this.femaleEmployees,
            this.getMean(this.tableData.map(({ meanWageGap }) => meanWageGap)) / 100,
            this.getMean(this.tableData.map(({ medianWageGap }) => medianWageGap)) / 100,
          ];
          const barValues = [
            this.maleEmployees / this.filteredEmployees.length,
            this.femaleEmployees / this.filteredEmployees.length,
            this.getMean(this.tableData.map(({ meanWageGap }) => meanWageGap)) / 100,
            this.getMean(this.tableData.map(({ medianWageGap }) => medianWageGap)) / 100,
          ];
          const colors = [
            ['#5382F0', '#D7E6FF'],
            ['#FE6695', '#FEDDDC'],
            ['#70B5C0', '#e3fcec'],
            ['#70B5C0', '#e3fcec'],
          ];
          const positions = [1.25, 3.25, 5.25, 7.25];
          titles.forEach((title, index) => {
            slide.addTable(
              this.getPPTCard(
                title,
                values[index],
                (barValues[index] * 100).toFixed(1),
                colors[index][0],
              ),
              {
                x: positions[index],
                y: 0.3,
                w: 1.5,
                color: colors[index][0],
                align: 'left',
                border: { pt: '2', color: colors[index][0] },
              },
            );
            slide.addChart(
              pptx.ChartType.bar,
              [
                {
                  labels: [title],
                  values: [this.maleEmployees / this.filteredEmployees.length],
                },
                {
                  labels: ['otro'],
                  values: [1 - this.maleEmployees / this.filteredEmployees.length],
                },
              ],
              {
                x: positions[index],
                y: 0.65,
                ...config2,
                chartColors: colors[index],
              },
            );
          });
        }
        slide.addChart(
          i === 0 ? pptx.ChartType.pie : i === 1 ? pptx.ChartType.bar : pptx.ChartType.line,
          chartData,
          {
            ...config,
            h: i === 0 ?  4.3 : 5,
            y: i === 0 ? 1.1 : 0.31,
            showValue: i !== 2,
            title:
              i === 0
                ? 'Género de puestos'
                : i === 1
                ? 'Brecha salarial nivel laboral'
                : 'Brecha salarial en el tiempo',
            barDir: 'bar',
          },
        );
      });

      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },

    wageGap(malesTotal, femalesTotal) {
      if (malesTotal === 0 || femalesTotal === 0) return 0;
      return parseFloat((((malesTotal - femalesTotal) / malesTotal) * 100 || 0).toFixed(1));
    },

    getMean(arr) {
      return parseFloat(arr.reduce((p, c, i) => p + (c - p) / (i + 1), 0).toFixed(1), 10);
    },

    getRangePosition(timeStamp) {
      const d = new Date(timeStamp.seconds * 1000);
      const today = new Date();
      const age = today.getFullYear() - d.getFullYear();
      let selectedRange = -1;
      this.yearsRange.forEach((range) => {
        if (range.from < age && range.to >= age) selectedRange = `${range.from}-${range.to}`;
      });
      return selectedRange;
    },

    getEmployeeValue(employee, categoryId, date) {
      const currTime = this.YMDToTimeStamp(date).seconds;
      let resultIndex = -1;
      const some = Object.keys(employee.history[categoryId])
        .sort((a, b) => (a > b ? 1 : -1))
        .some((timestamp, index) => {
          resultIndex = index;
          return timestamp > currTime;
        });
      if (some) {
        return employee.history[categoryId][
          Object.keys(employee.history[categoryId]).sort((a, b) => (a > b ? 1 : -1))[
            resultIndex - 1
          ]
        ];
      }
      return employee[categoryId];
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, genderRatio: state.company.genderRatio || 0.6 }),
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    maleEmployees() {
      return this.filteredEmployees.filter((employee) => employee.gender === 'Masculino').length;
    },

    femaleEmployees() {
      return this.filteredEmployees.filter((employee) => employee.gender === 'Femenino').length;
    },

    data() {
      /* eslint-disable */

      const data = {};
      const category = this.categories.find((cat) => cat.id === 'nivellaboral');
      const position = this.categories.find((cat) => cat.id === 'puesto');
      const categoryOptions = this.options.filter(({ categoryId }) => categoryId === category.id);
      const years = this.yearsFilter.options.map(({ name }) => name);
      years.forEach((year) => {
        data[year] = {};
        this.months.forEach((month) => {
          data[year][month] = {};
          categoryOptions.forEach(({ id }) => {
            data[year][month][id] = {};
          });
        });
      });

      this.filteredEmployees.forEach((employee) => {
        const categoryValue = employee[category.id];
        const positionValue = employee[position.id];
        const rangePosition = this.getRangePosition(employee.entryDate);
        years.forEach((year) => {
          this.months.forEach((month, index) => {
            const date = `${year}-${index + 1 < 10 ? `0${index + 1}` : index + 1}-${new Date(
              year,
              index + 1,
              0,
            ).getDate()}`;
            if (!data[year][month][categoryValue][positionValue])
              data[year][month][categoryValue][positionValue] = {};
            if (!data[year][month][categoryValue][positionValue][rangePosition]) {
              data[year][month][categoryValue][positionValue][rangePosition] = [];
            }
            data[year][month][categoryValue][positionValue][rangePosition].push({
              gender: employee.gender,
              miniumWage: this.getEmployeeValue(employee, 'miniumWage', date),
            });
          });
        });
      });
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  height: 100%;
  .content {
    overflow: auto;
    gap: 1rem;
    display: grid;
    grid-template-areas:
      'male male female female wagegap wagegap wagegap2 wagegap2'
      'position position categories categories categories categories categories categories'
      'position position categories categories categories categories categories categories'
      'time time time time time time time time';
    grid-template-rows: 8rem 10rem 10rem 25rem;
    grid-template-columns:
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem)
      calc(12.5% - 1rem);

    & > *:not(.card__section) {
      height: 100%;
      display: flex;
      flex-flow: column;
      border: solid 1px var(--gray-color-500);
      border-radius: 3px;
      padding: 1rem;
      &:nth-child(5) {
        grid-area: position;
      }
      &:nth-child(6) {
        grid-area: categories;
      }
      &:nth-child(7) {
        grid-area: time;
      }
    }
  }

  .card__section:first-child {
    grid-area: male;
  }
  .card__section:nth-child(2) {
    grid-area: female;
  }
  .card__section:nth-child(3) {
    grid-area: wagegap;
  }
  .card__section:nth-child(4) {
    grid-area: wagegap2;
  }

  .card__section {
    height: 100%;
  }
}
</style>
