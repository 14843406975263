<template>
  <div class="tabs">
    <ul>
      <li
        v-for="(tab, index) in tabList"
        :key="index"
        :class="{
          'tab--isActive': index + 1 === activeTab,
          'tab__text--white': index + 1 !== activeTab,
        }"
      >
        <label :for="`${_uid}${index}`" class="tab__label">
          <unicon :name="tab.icon" fill="currentColor" height="16px" width="16px" />
          {{ tab.title }}
        </label>
        <input
          :id="`${_uid}${index}`"
          type="radio"
          :name="`${_uid}-tab`"
          :value="index + 1"
          v-model="activeTab"
          class="hidden"
          @click="$emit('tabChange', tabList[index].title)"
        />
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <div :key="index" v-show="index + 1 === activeTab">
        <slot :name="`tabPanel-${index + 1}`" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.tabs {
  display: flex;
  flex-flow: column;
  height: 100%;
  & > ul {
    display: flex;
    gap: 0.7rem;
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    font-size: 0.9rem;
    position: fixed;
    z-index: 9996;
    background-color: white;
  }

  & > div {
    flex-grow: 1;
    overflow: auto;
    padding: calc(1.2rem + 32.8px) 0;
    padding-bottom: 1rem;
  }
}

.tab__label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem;
  border-radius: 5px;
  &:hover {
    background-color: var(--gray-color-100);
  }
}

.tab__label {
  color: var(--font-color-600);
}

.tab--isActive {
  padding-bottom: 0.3rem;
  border-bottom: solid 2.2px var(--main-color-500);

  .tab__label {
    color: var(--main-color-500);
  }
}
</style>
