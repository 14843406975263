<template>
  <div class="buttons">
    <ul>
      <li
        v-for="(button, index) in buttonList"
        :key="index"
        :class="{
          'button--isActive': button.value === activebutton,
          'button__text--white': button.value !== activebutton,
        }"
      >
        <label :for="`${_uid}${index}`" class="button__label">
          <unicon
            v-if="button.icon"
            :name="button.icon"
            fill="currentColor"
            height="16px"
            width="16px"
          />
          {{ button.label }}
        </label>
        <input
          :id="`${_uid}${index}`"
          type="radio"
          :name="`${_uid}-button`"
          :value="button.value"
          v-model="activebutton"
          class="hidden"
          @click="$emit(button.value)"
          @input="$emit('input', button.value)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    buttonList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activebutton: null,
    };
  },
  mounted() {
    this.activebutton = this.buttonList[0].value;
  },
};
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.buttons {
  display: flex;
  flex-flow: column;
  height: 35px;
  width: fit-content;
  background-color: var(--gray-color-100);
  & > ul {
    height: 100%;
    display: flex;
    list-style: none;
    overflow: auto;
    white-space: nowrap;
    font-size: 0.825rem;
    border-radius: 4px;
    font-weight: var(--medium);
    width: fit-content;
    border: 1.5px solid var(--gray-color-500);
    li {
      height: 100%;
      display: flex;
      place-items: center;
      background-color: var(--gray-color-100);
      border: solid 1px var(--gray-color-100);
      &:hover {
        background-color: white;
      }
    }

    .button--isActive {
      border: 1px solid var(--gray-color-100);
      border-radius: 4px;
      background-color: white;
      .button__label {
        color: var(--main-color-500);
      }
    }
  }

  & > div {
    height: calc(100% - 1rem);
    overflow: auto;
    padding: 1.2rem 0;
  }
}

.button__label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.8rem;
  border-radius: 5px;
  color: var(--font-color-300);
}
</style>
