<template>
  <div>
    <Modal size="sm" ref="modal__yearsRange">
      <template #title>Editar rango</template>
      <template #content>
        <validation-observer tag="div" v-slot="{ handleSubmit, invalid }" class="form__validation">
          <form @submit.prevent="handleSubmit(editYearsRange)">
            <div class="yearsRange__modal">
              <p>
                Determina el rango que deseas identificar para cálculo de las brechas salariales en
                tu organización. Los números determinan los años.
              </p>

              <span class="input__range">
                <custom-input
                  v-model="inputRange"
                  type="range"
                  min="1"
                  rules="required|positive|min_value:1"
                />
                <p>{{ range }}</p>
              </span>

              <h4>Años</h4>
              <span class="preview" v-if="inputRange">
                <p v-for="range in yearsRange" :key="`${range.from}-${range.to}`">
                  {{ range.from }} - {{ range.to }}
                </p>
              </span>
              <span v-else class="no-preview"><p>No preview</p></span>
            </div>

            <div class="modal__buttons">
              <Button @click="close" size="medium" variant="text">Cancelar</Button>
              <Button type="submit" :disabled="invalid" size="medium" variant="primary">
                {{ loading ? 'Editando...' : 'Editar' }}
              </Button>
            </div>
          </form>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Modal from '@/components/Modal.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';

export default {
  name: 'YearsRangeModal',
  components: {
    Modal,
    ValidationObserver,
    Button,
    CustomInput,
  },
  data() {
    return {
      inputRange: 0,
      loading: false,
    };
  },

  props: {},

  methods: {
    ...mapActions(['updateCompany']),
    ...mapMutations(['setAlert']),
    open() {
      this.$refs.modal__yearsRange.open();
    },

    close() {
      this.$refs.modal__yearsRange.close();
    },

    getEmploymentAge(timeStamp) {
      const d = new Date(timeStamp.seconds * 1000);
      const today = new Date();
      const age = today.getFullYear() - d.getFullYear();
      return age;
    },

    async editYearsRange() {
      try {
        this.loading = true;
        await this.updateCompany({
          ...this.company,
          yearsRange: this.range,
        });
        this.setAlert({
          state: 'success',
          message: 'Rango actualizado',
        });
      } catch (e) {
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.loading = false;
      }
      this.close();
    },
  },

  mounted() {
    this.inputRange = this.company.yearsRange;
  },

  computed: {
    ...mapState({
      company: (state) => ({ ...state.company, yearsRange: state.company.yearsRange || 10 }),
      employees: (state) => state.employees.employees,
    }),

    range() {
      return parseFloat(this.inputRange);
    },

    yearsRange() {
      const result = [];
      for (
        let index = 0;
        index
        <= Math.max(...this.employees.map(({ entryDate }) => this.getEmploymentAge(entryDate)));
        index += this.range
      ) {
        result.push({ from: index, to: index + this.range });
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.yearsRange__modal {
  padding: 1.5rem 1.5rem;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
  gap: 1rem;
  p {
    line-height: 1rem;
    color: var(--font-color-900);
  }

  .preview {
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 5px;
    overflow: hidden;
    p {
      padding: 0.5rem;
      width: 5rem;
      border: solid 1px var(--gray-color-500);
    }
  }

  .input__range {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-right: 1rem;
    & > div {
      font-weight: var(--semi-bold);
      padding: .3rem .5rem;
      border-radius: 8px;
    }
  }

  .no-preview {
    padding: 0;
  }
}

form {
  & > div {
    display: flex;
  }
}

.modal__buttons {
  span {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

h4 {
  color: var(--font-color-200);
}
</style>
