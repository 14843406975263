<template>
  <div class="category-chart__section">
    <div class="section__title">
      <h4>Brecha salarial nivel laboral</h4>
    </div>
    <div class="chart__container">
      <bar-chart
        class="chart"
        :chart-data="categoryData"
        :chart-options="{
          stepSize: 0.1,
          indexAxis: 'y',
          scales: {
            y: {
              position: 'right',
            },
          },
          plugins: {
            tooltip: {
              yAlign: 'bottom',
            },
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BarChart from '@/components/charts/BarChart.vue';

export default {
  components: {
    BarChart,
  },
  props: {
    filteredEmployees: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
  },
  computed: {
    ...mapState({
      company: (state) => ({
        ...state.company,
        colors: state.company.colors,
      }),
    }),
    categoryData() {
      const datasets = [
        {
          data: this.tableData.map(({ meanWageGap }) => meanWageGap / 100),
          backgroundColor: this.company.colors[0],
          borderColor: this.company.colors[0],
          label: 'Media',
        },
        {
          data: this.tableData.map(({ medianWageGap }) => medianWageGap / 100),
          backgroundColor: this.company.colors[1],
          borderColor: this.company.colors[1],
          label: 'Mediana',
        },
      ];
      const labels = this.tableData.map(({ categoryOption }) => categoryOption.name);
      return {
        datasets,
        labels,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.category-chart__section {
  .chart__container {
    flex-grow: 1;
    .chart {
      height: 100%;
    }
  }
  .chart {
    height: 100%;
  }
}
</style>
