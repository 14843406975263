<template>
  <div class="line-chart__section">
    <div class="section__title">
      <h4>Brecha salarial en el tiempo</h4>
      <ButtonGroup
        v-model="lineChartView"
        :buttonList="[
          { label: 'Años', value: 'years' },
          { label: 'Meses', value: 'months' },
        ]"
      />
      <span>
        <Menu
          size="medium"
          direction="left"
          :closeOnItemClick="false"
          :disabled="lineChartView === 'months'"
        >
          <template #label>
            <filter-item :filter="fromYearsFilter" />
          </template>
          <template #options>
            <menu-item
              v-for="option in [...fromYearsFilter.options].filter(
                ({ name }) =>
                  parseInt(name, 10) <
                  parseInt(toYearsFilter.options.find(({ active }) => active).name, 10),
              )"
              @click="onYearChange(option.index, fromYearsFilter.name)"
              :key="option.id"
              :disabled="lineChartView === 'months'"
            >
              {{ option.name }}
              <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
            </menu-item>
          </template>
        </Menu>
        <Menu
          size="medium"
          direction="left"
          :closeOnItemClick="false"
          :disabled="lineChartView === 'months'"
        >
          <template #label>
            <filter-item :filter="toYearsFilter" />
          </template>
          <template #options>
            <menu-item
              v-for="option in [...toYearsFilter.options]
                .reverse()
                .filter(
                  ({ name }) =>
                    parseInt(name, 10) >
                    parseInt(fromYearsFilter.options.find(({ active }) => active).name, 10),
                )"
              @click="onYearChange(option.index, toYearsFilter.name)"
              :key="option.id"
              :disabled="lineChartView === 'months'"
            >
              {{ option.name }}
              <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
            </menu-item>
          </template>
        </Menu>
        <Menu
          size="medium"
          direction="left"
          :closeOnItemClick="false"
          :disabled="lineChartView === 'years'"
        >
          <template #label>
            <filter-item :filter="yearsFilter" />
          </template>
          <template #options>
            <menu-item
              v-for="option in [...yearsFilter.options].reverse()"
              @click="onYearChange(option.index)"
              :key="option.id"
              :disabled="lineChartView === 'years'"
            >
              {{ option.name }}
              <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
            </menu-item>
          </template>
        </Menu>
      </span>
    </div>
    <div class="chart__container">
      <line-chart
        class="chart"
        :chart-data="
          lineChartView === 'months'
            ? chartDataMonths[parseInt(yearsFilter.options.find(({ active }) => active).name, 10)]
            : chartDataYears
        "
        :chart-options="{
          plugins: {
            tooltip: {
              callbacks: {},
            },
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LineChart from '@/components/charts/LineChart.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import ButtonGroup from '@/components/buttons/ButtonGroup.vue';

export default {
  components: {
    LineChart,
    Menu,
    MenuItem,
    FilterItem,
    ButtonGroup,
  },
  data() {
    return {
      isTableView: false,
      lineChartView: 'years',
      fromYearsFilter: {
        id: 'fromYearsId',
        name: 'Desde',
        options: [],
      },
      toYearsFilter: {
        id: 'toYearsId',
        name: 'Hasta',
        options: [],
      },
    };
  },

  props: {
    filteredEmployees: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Object,
    },
    yearsFilter: {
      required: true,
      type: Object,
    },
    months: {
      required: true,
      type: Array,
    },
  },

  mounted() {
    this.fillYears();
  },

  methods: {
    sortByMonth(arr) {
      return arr.sort((a, b) => this.months.indexOf(a) - this.months.indexOf(b));
    },
    categoryMeanWageGap(positions) {
      return this.getMean(
        Object.keys(positions).map((key) => this.positionMeanWageGap(positions[key])),
      );
    },
    positionMeanWageGap(entryYears) {
      let wageGapCont = 0;
      Object.keys(entryYears).forEach((key) => {
        wageGapCont += Math.max(
          this.wageGap(
            this.getMean(
              entryYears[key]
                .filter((employee) => employee.gender === 'Masculino')
                .map((employee) => employee.miniumWage),
            ),
            this.getMean(
              entryYears[key]
                .filter((employee) => employee.gender === 'Femenino')
                .map((employee) => employee.miniumWage),
            ),
          ),
          0,
        );
      });
      return parseFloat((wageGapCont / Object.keys(entryYears).length || 0).toFixed(1));
    },
    getMean(arr) {
      return parseFloat(arr.reduce((p, c, i) => p + (c - p) / (i + 1), 0).toFixed(1), 10);
    },
    getMedian(arr) {
      const mid = Math.floor(arr.length / 2);
      const nums = [...arr].sort((a, b) => a - b);
      return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
    },
    wageGap(malesTotal, femalesTotal) {
      if (malesTotal === 0 || femalesTotal === 0) return 0;
      return parseFloat((((malesTotal - femalesTotal) / malesTotal) * 100 || 0).toFixed(1));
    },
    categoryMedianWageGap(positions) {
      return this.getMean(
        Object.keys(positions).map((key) => this.positionMedianWageGap(positions[key])),
      );
    },
    positionMedianWageGap(entryYears) {
      let wageGapCont = 0;
      Object.keys(entryYears).forEach((key) => {
        wageGapCont += Math.max(
          this.wageGap(
            this.getMedian(
              entryYears[key]
                .filter((employee) => employee.gender === 'Masculino')
                .map((employee) => employee.miniumWage),
            ),
            this.getMedian(
              entryYears[key]
                .filter((employee) => employee.gender === 'Femenino')
                .map((employee) => employee.miniumWage),
            ),
          ),
          0,
        );
      });
      return parseFloat((wageGapCont / Object.keys(entryYears).length || 0).toFixed(1));
    },
    getEntryYear(timeStamp) {
      const d = new Date(timeStamp.seconds * 1000);
      return d.getFullYear();
    },
    onYearChange(optionIndex, filterName) {
      let selectedFilter = this.yearsFilter;
      if (filterName === 'Desde') selectedFilter = this.fromYearsFilter;
      if (filterName === 'Hasta') selectedFilter = this.toYearsFilter;
      selectedFilter.options.forEach((_, index) => {
        const item = selectedFilter.options[index];
        if (item.index === optionIndex) {
          item.active = true;
        } else item.active = false;
      });
    },
    fillYears() {
      const currentYear = new Date().getFullYear();
      let startYear = currentYear;
      this.filteredEmployees.forEach(({ entryDate }) => {
        const entryYear = parseInt(this.getEntryYear(entryDate), 10);
        if (entryYear < startYear) startYear = entryYear;
      });
      let cont = 0;
      while (startYear <= currentYear) {
        this.yearsFilter.options.push({
          id: `${startYear}Id`,
          name: startYear,
          active: false,
          index: cont,
        });
        cont += 1;
        startYear += 1;
      }
      this.fromYearsFilter.options = [...this.yearsFilter.options.map((option) => ({ ...option }))];
      this.fromYearsFilter.options[0].active = true;
      this.yearsFilter.options[this.yearsFilter.options.length - 1].active = true;
      this.toYearsFilter.options = [...this.yearsFilter.options.map((option) => ({ ...option }))];
    },
  },

  computed: {
    ...mapState({
      company: (state) => ({
        ...state.company,
        genderRatio: state.company.genderRatio || 0.6,
        colors: state.company.colors,
      }),
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
    }),

    dates() {
      /* eslint-disable */
      const meanResults = {};
      const medianResults = {};
      Object.keys(this.data || {}).forEach((year) => {
        meanResults[year] = { monthsWageGaps: {}, wageGap: 0 };
        medianResults[year] = { monthsWageGaps: {}, wageGap: 0 };
        let meanWageGapYearCont = 0;
        let medianWageGapYearCont = 0;
        Object.keys(this.data[year]).forEach((month) => {
          const meanWageGapMonth = this.getMean(
            Object.keys(this.data[year][month]).map((categoryId) => {
              const arr = this.data[year][month][categoryId];
              return this.categoryMeanWageGap(arr);
            }),
          );
          const medianWageGapMonth = this.getMean(
            Object.keys(this.data[year][month]).map((categoryId) => {
              const arr = this.data[year][month][categoryId];
              return this.categoryMedianWageGap(arr);
            }),
          );
          meanResults[year].monthsWageGaps[month] = meanWageGapMonth;
          medianResults[year].monthsWageGaps[month] = medianWageGapMonth;
          meanWageGapYearCont += Math.max(meanWageGapMonth, 0);
          medianWageGapYearCont += Math.max(medianWageGapMonth, 0);
        });
        meanResults[year].wageGap = parseFloat(
          (meanWageGapYearCont / Object.keys(this.data[year] || 0).length).toFixed(2),
        );
        medianResults[year].wageGap = parseFloat(
          (medianWageGapYearCont / Object.keys(this.data[year] || 0).length).toFixed(2),
        );
      });

      return [meanResults, medianResults];
    },

    chartDataMonths() {
      const result = {};
      this.yearsFilter.options
        .map(({ name }) => parseInt(name, 10))
        .forEach((name) => {
          result[name] = {
            datasets: [
              {
                backgroundColor: this.company.colors[0],
                borderColor: this.company.colors[0],
                label: 'Media',
              },
              {
                backgroundColor: this.company.colors[1],
                borderColor: this.company.colors[1],
                label: 'Mediana',
              },
            ],
          };
          result[name].datasets[0].data = Object.values(
            this.dates[0][name] ? this.dates[0][name].monthsWageGaps : [],
          );
          result[name].datasets[1].data = Object.values(
            this.dates[1][name] ? this.dates[1][name].monthsWageGaps : [],
          );
          result[name].labels = this.sortByMonth(
            Object.keys(this.dates[0][name] ? this.dates[0][name].monthsWageGaps : []),
          ).map((val) => val.charAt(0).toUpperCase() + val.slice(1));
          result[name].datasets.forEach((dataset) => {
            if (dataset.data) dataset.data = dataset.data.map((val) => val / 100);
          });
        });
      return result;
    },

    chartDataYears() {
      const datasets = [
        {
          backgroundColor: this.company.colors[0],
          borderColor: this.company.colors[0],
          label: 'Media',
        },
        {
          backgroundColor: this.company.colors[1],
          borderColor: this.company.colors[1],
          label: 'Mediana',
        },
      ];
      let labels = [];
      if (this.fromYearsFilter.options.length) {
        const startYear = parseInt(
          this.fromYearsFilter.options.find(({ active }) => active).name,
          10,
        );
        const endYear = parseInt(this.toYearsFilter.options.find(({ active }) => active).name, 10);
        const isYearInRange = (year) =>
          parseInt(year, 10) >= startYear && parseInt(year, 10) <= endYear;
        datasets[0].data = Object.keys(this.dates[0])
          .filter((year) => isYearInRange(year))
          .map((year) => this.getMean(Object.values(this.dates[0][year].monthsWageGaps)));
        datasets[1].data = Object.keys(this.dates[1])
          .filter((year) => isYearInRange(year))
          .map((year) => this.getMean(Object.values(this.dates[1][year].monthsWageGaps)));
        labels = this.yearsFilter.options
          .map(({ name }) => name)
          .filter((year) => isYearInRange(year));
      }
      datasets.forEach((dataset) => {
        if (dataset.data) dataset.data = dataset.data.map((val) => val / 100);
      });

      return {
        datasets,
        labels,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.line-chart__section {
  .chart__container {
    flex-grow: 1;
    .chart {
      height: 100%;
    }
  }
  .section__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    h4 + div {
      margin-left: 13.2rem;
    }
    span {
      display: flex;
      gap: 0.8rem;
    }
  }
}
</style>
