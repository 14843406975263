<template>
  <Doughnut
    :chart-data="chartData"
    :chart-options="{
      ...chartOptions,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        ...chartOptions.plugins,

        legend: {
          ...(chartOptions.plugins.legend || {}),
          labels: {
            ...(chartOptions.plugins.legend ? chartOptions.plugins.legend.labels : {}),
            boxWidth: 10,
            boxHeight: 9,
            color: '#9096a5',
            font: {
              size: 12,
              weight: 500,
              family: 'Inter',
            },
          },
        },
        datalabels: {
          ...(chartOptions.plugins.datalabels || {}),
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          },
          formatter: (value, context) => {
            return value ? Math.round(value * 100) + '%' : '';
          },
          color: '#fff',
        },
        tooltip: {
          ...(chartOptions.plugins.tooltip || {}),
          xAlign: 'center',
          yAlign: 'bottom',
          bodyAlign: 'left',
          boxWidth: 10,
          boxHeight: 10,
          boxPadding: 5,
          displayColors: false,
          titleFont: {
            size: 11,
            weight: 600,
            family: 'Inter',
          },
          bodyFont: {
            size: 11,
            weight: 500,
            family: 'Inter',
          },
          bodySpacing: 3,
          padding: {
            left: 12,
            right: 16,
            top: 11,
            bottom: 11,
          },
        },
      },
    }"
    :option="chartOptions"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  Title,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);

export default {
  name: 'DoughnutChart',
  components: { Doughnut },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>
