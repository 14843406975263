<template>
  <LineJS
    :chart-data="chartData"
    :chart-options="{
      ...chartOptions,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        ...(chartOptions.scales || {}),
        ['y']: {
          ...(chartOptions.scales ? chartOptions.scales['y'] : {}),
          // max: 1,
          ticks: {
            // steps: 5,
            // stepSize: 0.2,
            color: '#9096a5',
            font: {
              size: 11,
              weight: 500,
              family: 'Inter',
            },
            callback: function (value) {
              return parseFloat((value * 100).toFixed(2)) + '%';
            },
          },
        },
        ['x']: {
          ...(chartOptions.scales ? chartOptions.scales[chartOptions.indexAxis] : {}),
          ticks: {
            ...(chartOptions.scales ? chartOptions.scales[chartOptions.indexAxis].ticks : {}),
            color: '#9096a5',
            font: {
              size: 11,
              weight: 500,
              family: 'Inter',
            },
          },
        },
      },
      plugins: {
        ...chartOptions.plugins,
        legend: {
          ...(chartOptions.plugins.legend || {}),
          labels: {
            ...(chartOptions.plugins.legend ? chartOptions.plugins.legend.labels : {}),
            boxWidth: 10,
            boxHeight: 9,
            color: '#9096a5',
            font: {
              size: 12,
              weight: 500,
              family: 'Inter',
            },
          },
        },
        datalabels: {
          display: false,
        },
        tooltip: {
          ...(chartOptions.plugins.tooltip || {}),
          bodyAlign: 'left',
          boxWidth: 10,
          boxHeight: 10,
          boxPadding: 5,
          displayColors: true,
          titleFont: {
            size: 11,
            weight: 800,
            family: 'Inter',
          },
          bodyFont: {
            size: 11,
            weight: 500,
            family: 'Inter',
          },
          bodySpacing: 3,
          padding: {
            left: 12,
            right: 16,
            top: 11,
            bottom: 11,
          },
        },
      },
    }"
    :option="chartOptions"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineJS } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
);

export default {
  name: 'LineChart',
  components: { LineJS },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>
