var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gender-chart__section"},[_vm._m(0),_c('div',{staticClass:"chart__container"},[_c('doughnut-chart',{staticClass:"chart",attrs:{"chart-data":_vm.genderData,"chart-options":{
        cutout: 0,
        radius: '95%',
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
      }}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section__title"},[_c('h4',[_vm._v("Género de puestos")])])}]

export { render, staticRenderFns }