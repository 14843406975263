<template>
  <Bar
    :chart-data="chartData"
    :chart-options="{
      ...chartOptions,
      responsive: true,
      maintainAspectRatio: false,
      borderRadius: 4,
      scales: {
        ...(chartOptions.scales || {}),
        [chartOptions.indexAxis === 'y' ? 'x' : 'y']: {
          ...(chartOptions.scales
            ? chartOptions.scales[chartOptions.indexAxis === 'y' ? 'x' : 'y']
            : {}),
          max: chartOptions.max,
          ticks: {
            steps: chartOptions.steps,
            stepSize: chartOptions.stepSize,
            color: '#9096a5',
            font: {
              size: 11,
              weight: 500,
              family: 'Inter',
            },
            callback: function (value) {
              return Math.round(value * 100) + '%';
            },
          },
        },
        [chartOptions.indexAxis]: {
          ...(chartOptions.scales ? chartOptions.scales[chartOptions.indexAxis] : {}),
          ticks: {
            ...(chartOptions.scales ? chartOptions.scales[chartOptions.indexAxis].ticks : {}),
            color: '#9096a5',
            font: {
              size: 11,
              weight: 500,
              family: 'Inter',
            },
          },
        },
      },
      plugins: {
        ...chartOptions.plugins,
        legend: {
          ...(chartOptions.plugins.legend || {}),
          labels: {
            ...(chartOptions.plugins.legend ? chartOptions.plugins.legend.labels : {}),
            boxWidth: 10,
            boxHeight: 9,
            color: '#9096a5',
            font: {
              size: 12,
              weight: 500,
              family: 'Inter',
            },
          },
        },
        datalabels: {
          font: {
            size: 12,
            weight: 500,
            family: 'Inter',
          },
          formatter: (value, context) => {
            return value ? (value * 100).toFixed(0) + '%' : '';
          },
          color: '#fff',
          ...chartOptions.plugins.datalabels,
        },
        tooltip: {
          ...(chartOptions.plugins.tooltip || {}),
          bodyAlign: 'left',
          boxWidth: 10,
          boxHeight: 10,
          boxPadding: 5,
          displayColors: false,
          titleFont: {
            size: 11,
            weight: 800,
            family: 'Inter',
          },
          bodyFont: {
            size: 11,
            weight: 500,
            family: 'Inter',
          },
          bodySpacing: 3,
          padding: {
            left: 12,
            right: 16,
            top: 11,
            bottom: 11,
          },
        },
      },
    }"
    :option="chartOptions"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
  },
};
</script>
